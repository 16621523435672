
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Component as VueComponent } from 'vue';
import { TableConfigurations, TableRow } from './LegacyTable';
import IconComponent from '@/components/IconComponent.vue';
import { formatDate } from '@/services/formatService';

@Component<BaseTableComponent>({ components: { IconComponent } })
export default class BaseTableComponent extends Vue {
  @Prop({
    default: () => []
  })
  columns!: string[];

  @Prop({
    default: () => []
  })
  rows!: TableRow[];

  @Prop({
    default: false
  })
  isSortable!: boolean;

  @Prop()
  sortColumn?: string;

  @Prop({
    default: false
  })
  isSelectable!: boolean;

  @Prop({
    default: false
  })
  isRowClickable!: boolean;

  @Prop({
    default: false
  })
  isAscendingSortOrder!: boolean;

  @Prop({
    default: false
  })
  isScrollable!: boolean;

  @Prop()
  tableConfigurations?: TableConfigurations;

  @Prop({ default: false })
  compact!: boolean;

  @Prop({ default: false })
  columnDataTextWrapping!: boolean;

  @Prop({
    default: () => {
      return (item: string) => item;
    }
  })
  formatter!: () => unknown;

  @Prop({ default: () => [] })
  sortedData!: TableRow[];

  @Prop({ default: null })
  activeHoverRow!: number | null;

  sortData(column: string): void {
    this.$emit('sortData', column);
  }

  format(data: string | Date): string {
    if (data instanceof Date) {
      return formatDate(data);
    }
    return data;
  }

  getComponent(columnIndex: number): VueComponent | undefined {
    const columnName = this.columns[columnIndex];
    if (columnName && this.tableConfigurations?.[columnName]) {
      const componentInTableConfig =
        this.tableConfigurations?.[columnName]?.component;
      if (componentInTableConfig) {
        if ('componentRef' in componentInTableConfig) {
          return componentInTableConfig?.componentRef;
        } else {
          return componentInTableConfig;
        }
      }
    }
  }

  getComponentProps(
    columnIndex: number,
    cellItem: unknown,
    rowDataObject: Record<string | number, unknown>
  ): unknown {
    const columnName = this.columns[columnIndex];
    if (columnName && this.tableConfigurations?.[columnName]) {
      const componentInTableConfig =
        this.tableConfigurations?.[columnName]?.component;
      if (componentInTableConfig) {
        if (
          'componentProps' in componentInTableConfig &&
          typeof componentInTableConfig?.componentProps === 'function'
        ) {
          return componentInTableConfig?.componentProps?.(
            cellItem,
            rowDataObject
          );
        }
      }
    }
  }

  emitRowClickedEvent(rowIndex: number): void {
    this.$emit('rowClicked', rowIndex);
  }

  emitCellEvent(column: string, rowIndex: number, payload: unknown): void {
    this.$emit('cellEvent', column, rowIndex, payload);
  }

  onHoverRow(rowIndex: number): void {
    this.$emit('hoverRow', rowIndex);
  }
}
