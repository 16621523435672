
import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ActionMenu from '@/components/ActionMenus/ActionMenuComponent.vue';
import { TableFilterTypes, TableFilterItem } from './LegacyTable';
import { NumberRange } from '@/models/NumberRange';
import NumberRangeComponent from '@/components/NumberRangeComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import Dropdown from '@/components/Forms/Elements/DropdownComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import clickOutside from '@/util/directives/clickOutside';

@Component<TableFilterComponent>({
  components: {
    ActionMenu,
    ButtonComponent,
    Dropdown,
    NumberRangeComponent,
    TextInput,
    IconComponent
  },
  directives: {
    clickOutside
  }
})
export default class TableFilterComponent extends Vue {
  @Prop({
    default: () => {
      return [] as TableFilterItem[];
    }
  })
  filters!: TableFilterItem[];

  @Prop()
  activeFilter?: TableFilterItem;

  @Prop({
    default: () => {
      return false;
    }
  })
  showMenu!: boolean;

  isNumber(item: TableFilterItem): boolean {
    return item.filterType === TableFilterTypes.NUMBER;
  }

  isString(item: TableFilterItem): boolean {
    return item.filterType === TableFilterTypes.STRING;
  }

  isBoolean(item: TableFilterItem): boolean {
    return item.filterType === TableFilterTypes.BOOLEAN;
  }

  isList(item: TableFilterItem): boolean {
    return item.filterType === TableFilterTypes.LIST;
  }

  toggleMenu(): void {
    this.$emit('toggleMenu');
  }

  setActiveFilter(filter: TableFilterItem): void {
    this.$emit('activeFilter', filter);
  }

  onRangeChange(item: TableFilterItem, numberRange: NumberRange): void {
    this.$emit('rangeChange', item, numberRange);
  }

  selectFilter(item: TableFilterItem, value: TableFilterTypes): void {
    this.$emit('selectFilter', item, value);
  }

  clearFilter(filter: TableFilterItem): void {
    this.$emit('clearFilter', filter);
    this.$emit('closeAllMenus');
  }

  closeSubMenus(): void {
    this.$emit('closeSubMenus');
  }

  closeAllMenus(): void {
    this.$emit('closeAllMenus');
  }
}
