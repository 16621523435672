import { Component, Prop, Vue } from 'vue-property-decorator';
import { TableFilterItem, TableFilterTypes } from './LegacyTable';
import { NumberRange } from '@/models/NumberRange';
import QuickFilterFilter from './LegacyQuickFilterFilter';

@Component
export class BaseTableFilter extends Vue {
  @Prop({
    default: false
  })
  isFilterable?: boolean;

  @Prop({
    default: () => []
  })
  quickFilterItems?: QuickFilterFilter[];

  @Prop({
    default: false
  })
  isQuickFilterable?: boolean;

  activeFilter: TableFilterItem | null = null;
  activeFilters: TableFilterItem[] = [];
  activeQuickFilter: QuickFilterFilter | null = null;
  activeQuickFilters: TableFilterItem[] = [];
  showMenu = false;

  get allActiveFilters(): TableFilterItem[] {
    return this.activeFilters.concat(this.activeQuickFilters);
  }

  /* Open or close menu */
  toggleMenu(): void {
    this.showMenu = !this.showMenu;
    if (!this.showMenu) {
      this.activeFilter = null;
    }
  }

  /* Close menu and sub menus */
  closeAllMenus(): void {
    this.showMenu = false;
    this.activeFilter = null;
  }

  /* Active filter drive the sub menu */
  tableFilterActiveFilter(item: TableFilterItem): void {
    this.activeFilter = item;
  }

  /* Filter has been selected, mutate the value */
  tableFilterSelectFilter(
    tableFilterItem: TableFilterItem,
    value: unknown
  ): void {
    let currentActiveFilter = this.activeFilters.find((activeFilter) => {
      return activeFilter.column === tableFilterItem.column;
    });
    if (!currentActiveFilter) {
      currentActiveFilter = new TableFilterItem(tableFilterItem);
      currentActiveFilter.value = value;
      this.activeFilters.push(currentActiveFilter);
    } else {
      currentActiveFilter.value = value;
    }
    /* Clear out filter if its an empty string - hides an empty filter */
    if (
      (tableFilterItem.filterType === TableFilterTypes.STRING ||
        tableFilterItem.filterType === TableFilterTypes.STRING_EXACT) &&
      value === ''
    ) {
      this.clearActiveFilter(tableFilterItem);
    }
  }

  filterRangeChange(
    tableFilterItem: TableFilterItem,
    numberRange: NumberRange
  ): void {
    let currentActiveFilter = this.activeFilters.find((activeFilter) => {
      return activeFilter.column === tableFilterItem.column;
    });
    if (!currentActiveFilter) {
      currentActiveFilter = new TableFilterItem(tableFilterItem);
      currentActiveFilter.numberRange = numberRange;
      this.activeFilters.push(currentActiveFilter);
    } else {
      currentActiveFilter.numberRange = numberRange;
    }
  }

  /* Clear an active filter, check both filter list as well as quick filter list */
  clearActiveFilter(tableFilterItem: TableFilterItem): void {
    this.activeFilters = this.activeFilters.filter((activeFilter) => {
      return tableFilterItem.column !== activeFilter.column;
    });
    this.activeQuickFilters = this.activeQuickFilters.filter((activeFilter) => {
      return tableFilterItem.column !== activeFilter.column;
    });
    if (this.activeQuickFilters.length < 1) {
      this.activeQuickFilter = null;
    }
  }

  /* Quick Filters click handler */
  quickFilterHandler(quickFilterItem: QuickFilterFilter): void {
    if (this.activeQuickFilter === quickFilterItem) {
      this.activeQuickFilter = null;
      this.activeQuickFilters = [];
      return;
    } else {
      this.activeQuickFilter = quickFilterItem;
      this.activeQuickFilters = [
        TableFilterItem.fromQuickFilter(quickFilterItem)
      ];
    }
  }
}
