var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "overflow-auto border rounded" }, [
    _c(
      "div",
      { staticClass: "flex flex-row w-full text-sm item-center" },
      [
        _vm._l(_vm.filters, function (filter, index) {
          return _c("div", { key: index }, [
            _c(
              "div",
              {
                staticClass: "border-r-2 border-l-1",
                class: _vm.filterIsActive(filter.filter) ? "bg-blue-400" : "",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "flex justify-between py-4 focus:outline-none",
                    on: {
                      click: function ($event) {
                        return _vm.handleFilterClick(filter)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex items-center px-2 mx-4 bg-white border-2 rounded",
                        class:
                          filter.countStyle != ""
                            ? filter.countStyle
                            : "bg-white",
                      },
                      [
                        filter.showIcon
                          ? _c("icon-component", {
                              staticClass: "ml-2",
                              attrs: { name: "star", width: 10, height: 10 },
                            })
                          : _vm._e(),
                        _c("span", { staticClass: "px-2 whitespace-nowrap" }, [
                          _vm._v(_vm._s(filter.count)),
                        ]),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "pr-4 whitespace-nowrap" }, [
                      _vm._v(_vm._s(filter.filter)),
                    ]),
                  ]
                ),
              ]
            ),
          ])
        }),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }