
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<RowSelectionComponent>({})
export default class RowSelectionComponent extends Vue {
  @Prop({ default: () => [] })
  rowIndexes!: number[];

  @Prop({ default: () => [] })
  selectedRowIndexes!: number[];

  @Prop({ default: false })
  compact?: boolean;

  get allRowsChecked() {
    return this.rowIndexes.every((index) =>
      this.selectedRowIndexes.includes(index)
    );
  }

  onRowChecked(isChecked: boolean, rowIndex: number) {
    this.$emit('rowChecked', isChecked, rowIndex);
  }

  onCheckAllRows(isChecked: boolean) {
    this.$emit('allRowsChecked', isChecked, this.rowIndexes);
  }
}
