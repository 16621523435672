
import { Component, Prop, Mixins, Vue, Watch } from 'vue-property-decorator';
import {
  TableConfiguration,
  TableConfigurations,
  TableSortType,
  TableSortFunctions,
  ColumnOrder,
  TableRow,
  ColumnSelectionItem,
  TableFilterItem
} from './LegacyTable';
import LegacyBaseTableComponent from './LegacyBaseTableComponent.vue';
import RowSelectionComponent from './LegacyRowSelectionComponent.vue';
import ColumnSelectionComponent from './LegacyColumnSelectionComponent.vue';
import SlideOutComponent from '@/components/SlideOutComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import TableFilterComponent from './LegacyTableFilterComponent.vue';
import { downloadCSV, selectElementContents } from '@/util/download-csv';
import QuickFilterComponent from './LegacyQuickFilterComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import SimpleTable from './LegacySimpleTableComponent.vue';
import ModalComponent from '@/components/Modals/ModalComponent.vue';
import EmptyTableMessageComponent from './LegacyEmptyTableMessageComponent.vue';
import ILocalStorageService from '@/models/LocalStorage/types';
import clickOutside from '@/util/directives/clickOutside';
import TableDropDownComponent from './Elements/LegacyTableDropDownComponent.vue';
import {
  paginationItemsPerPage,
  DropDownOptions
} from '@/models/Table/TableOption';
// Mixins
import { BaseTableFilter } from './LegacyTableFilter';

type TableStateConfiguration = {
  selectedColumns: string[];
  sortedColumns: string[];
  sortColumn: string;
  sortedPinnedColumns: string[];
  isAscendingSortOrder: boolean;
  numPaginatedRows: number;
};

@Component<LegacyTableComponent>({
  components: {
    LegacyBaseTableComponent,
    ButtonComponent,
    ColumnSelectionComponent,
    EmptyTableMessageComponent,
    ModalComponent,
    QuickFilterComponent,
    RowSelectionComponent,
    SimpleTable,
    SlideOutComponent,
    TableFilterComponent,
    IconComponent,
    TableDropDownComponent
  },
  directives: {
    clickOutside
  }
})
export default class LegacyTableComponent
  extends Mixins(Vue, BaseTableFilter)
  implements ILocalStorageService
{
  @Prop()
  data?: TableRow[];

  @Prop({
    default: false
  })
  isSortable!: boolean;

  @Prop()
  tableConfigurations?: TableConfigurations;

  @Prop()
  initialColumnOrder?: ColumnOrder;

  @Prop({
    default: false
  })
  isColumnEditable!: boolean;

  @Prop({
    default: null
  })
  defaultSortColumn!: string | null;

  @Prop()
  localStorageKey?: string;

  @Prop()
  emptyTableMessage?: string;

  @Prop({ default: 4 })
  maxPins!: number;

  @Prop({
    default: false
  })
  isSelectable!: boolean;

  @Prop({
    default: false
  })
  isRowClickable!: boolean;

  @Prop({
    default: false
  })
  canDownloadCSV!: boolean;

  @Prop({
    default: ''
  })
  csvFileName!: string;

  @Prop({
    default: false
  })
  compact!: boolean;

  @Prop({ default: false })
  columnDataTextWrapping!: boolean;

  //Customization for tables that need a different set of options
  @Prop({ default: () => paginationItemsPerPage })
  rowPaginationOptions!: DropDownOptions[];

  @Prop({ default: true })
  canEditPaginatedRows!: boolean;
  //can come from minitables or profilePipelinePage
  @Prop()
  staticPaginatedRows?: number;

  //Defaulting it to the prop causing some issues, the initial value here gets replaced.
  currentPaginatedRows = paginationItemsPerPage[0].value;

  get paginationRows(): number {
    return this.staticPaginatedRows ?? this.currentPaginatedRows;
  }

  set paginationRows(numRows: number) {
    this.currentPaginatedRows = numRows;
  }

  selectedRows: TableRow[] = [];
  selectedColumns: string[] = [];
  showColumnSettings = false;
  showColumnSettingsButtonClicked = false;
  sortedColumns: string[] = [];
  sortColumn: string | null = null;
  isAscendingSortOrder = false;
  sortedPinnedColumns: string[] = [];

  copyButtonText = 'Copy Table';

  paginationIndex = 0;
  showSimpleTable = false;
  SNACK_BAR_TIMEOUT = 3000;
  hoverRowIndex: number | null = null;

  $refs!: {
    simpleTable: SimpleTable;
  };

  @Watch('sortedData.length')
  watchDataLengthChange(newLength: number, previousLength: number): void {
    if (newLength < previousLength) {
      this.paginationIndex = 0;
    }
  }

  getRowFromEvent(rowIndex: number) {
    const indexOffset = this.paginationRows * this.paginationIndex;
    const index = indexOffset + rowIndex;
    return this.sortedData[index];
  }
  /* Cell click handler - re-emit */
  emitCellEvent(column: string, rowIndex: number, payload: unknown): void {
    this.$emit('cellEvent', column, this.getRowFromEvent(rowIndex), payload);
  }

  /* Cell click handler - re-emit */
  emitRowClickedEvent(rowIndex: number): void {
    this.$emit('rowClicked', this.getRowFromEvent(rowIndex));
  }
  /* Handler for row selection */
  onRowChecked(isSelected: boolean, rowIndex: number): void {
    const row = this.sortedData[rowIndex];
    const selectedIndex = this.selectedRows.findIndex((rw) => rw === row);
    if (!isSelected && selectedIndex > -1) {
      this.selectedRows.splice(selectedIndex, 1);
    } else if (selectedIndex === -1) {
      this.selectedRows.push(row);
    }
    this.$emit('selectedRows', this.selectedRows);
    this.$emit('rowsChecked', isSelected, [row]);
  }

  /* Handler for all row selections */
  onAllRowsChecked(isSelected: boolean, indexes: number[]): void {
    // The table data for the selected indexes regardless if selected or not
    const columnRows = this.sortedData.filter((_, index) =>
      indexes.includes(index)
    );
    if (isSelected) {
      const allSelectedIndexes = this.selectedRowIndexes.concat(indexes);
      this.selectedRows = this.sortedData.filter((_, index) =>
        allSelectedIndexes.includes(index)
      );
    } else {
      const currentIndexes = this.selectedRowIndexes.filter(
        (item) => !indexes.includes(item)
      );
      this.selectedRows = this.sortedData.filter((_, index) =>
        currentIndexes.includes(index)
      );
    }
    this.$emit('selectedRows', this.selectedRows.slice());
    this.$emit('rowsChecked', isSelected, columnRows.slice());
  }
  /* Show / Hide columns */
  onSelectColumn(column: ColumnSelectionItem, checked: boolean) {
    const index = this.selectedColumns.findIndex(
      (item) => item === column.name
    );
    if (checked && index === -1 && column.name) {
      this.selectedColumns.push(column.name);
    } else if (!checked && index > -1) {
      this.selectedColumns.splice(index, 1);
    }
  }
  /* Pin or un-pin a column. Need to remove/add from pinned array as well as sorted array */
  togglePinnedColumn(column: ColumnSelectionItem) {
    if (!column || !column.name) return;
    const pinnedIndex = this.sortedPinnedColumns.findIndex(
      (item) => item === column.name
    );
    const index = this.sortedColumns.findIndex((item) => item === column.name);

    if (column.isPinned && pinnedIndex > -1) {
      this.sortedColumns.push(column.name);
      this.sortedPinnedColumns.splice(pinnedIndex, 1);
    } else if (index > -1 && this.sortedPinnedColumns.length < this.maxPins) {
      this.sortedPinnedColumns.push(column.name);
      this.sortedColumns.splice(index, 1);
    }
  }
  /* Swap a column in the pinned columns array */
  swapPinnedColumns(
    srcColumn: ColumnSelectionItem,
    targetColumn: ColumnSelectionItem
  ): void {
    this.swap(this.sortedPinnedColumns, srcColumn, targetColumn);
  }
  /* Swap a column in the columns array */
  swapColumns(
    srcColumn: ColumnSelectionItem,
    targetColumn: ColumnSelectionItem
  ) {
    this.swap(this.sortedColumns, srcColumn, targetColumn);
  }
  /* Swap two columns in an array */
  swap(
    columns: string[],
    srcColumn: ColumnSelectionItem,
    targetColumn: ColumnSelectionItem
  ) {
    if (!srcColumn || !targetColumn) return;
    const srcIndex = columns.findIndex(
      (item: string) => item === srcColumn.name
    );
    const targetIndex = columns.findIndex(
      (item: string) => item === targetColumn.name
    );
    if (!columns[targetIndex]) {
      return;
    }
    const currentValue = columns[srcIndex];
    const targetValue = columns[targetIndex];
    // Swap values
    this.$set(columns, srcIndex, targetValue);
    this.$set(columns, targetIndex, currentValue);
  }
  /* Formats a column title, checks configuration otherwise un camel case the string */
  formatColumnTitle(columName: string) {
    const config = this.getConfig(columName);
    if (config && config?.columnTitle) {
      return config?.columnTitle;
    }
    return columName.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => {
      return str.toUpperCase();
    });
  }
  /* Helper to get loaded table configurations */
  getConfig(columnName: string): TableConfiguration | undefined {
    return this.tableConfigurations && this.tableConfigurations[columnName];
  }
  /* Changing the sortColumn value or the isAscendingSortOrder will update the computed columns */
  sortData(columnName: string) {
    if (columnName === this.sortColumn) {
      this.isAscendingSortOrder = !this.isAscendingSortOrder;
    }
    this.sortColumn = columnName;
    this.$emit('sortData', columnName);
  }

  /* Converts and downloads a CSV file of the Table data as reflected on the UI */
  downloadData() {
    const fileName = this.csvFileName
      ? this.csvFileName + `-${new Date().toLocaleString()}`
      : `Skypatch-${new Date().toLocaleString()}`;
    downloadCSV(this.tableData, fileName);
  }
  /* Adds the table data to the clipboard */
  async copyData() {
    this.showSimpleTable = true;
    try {
      await this.$nextTick(); // must wait for next digest cycle to get a handle on the DOM element
      const element = this.$refs.simpleTable;
      selectElementContents(element);
      this.copyButtonText = 'Table Copied';
      setTimeout(() => {
        this.copyButtonText = 'Copy Table';
      }, 5000);
      this.showSimpleTable = false;
    } catch (err: any) {
      this.showSimpleTable = false;
    }
  }

  /* Seed the column data, this would be the place for user preference data to get loaded */
  /* Also loads the drop down value from local storage */
  async loadConfiguration(): Promise<void> {
    const config = (await this.$store.dispatch(
      'localStorageModule/getItem',
      this.localStorageKey
    )) as TableStateConfiguration;

    // If table has a default sort column set this first
    if (this.defaultSortColumn) {
      this.sortColumn = this.defaultSortColumn;
    }
    if (config) {
      this.initSelectValue(config);
      this.selectedColumns = config.selectedColumns || this.allColumns.slice();
      this.sortedColumns = config.sortedColumns || this.allColumns.slice();
      this.sortColumn = config.sortColumn || '';
      this.isAscendingSortOrder = config.isAscendingSortOrder || false;
      this.sortedPinnedColumns = config.sortedPinnedColumns || [];
    } else if (this.initialColumnOrder) {
      const pinned = this.initialColumnOrder.pinned
        ? this.initialColumnOrder.pinned.slice()
        : [];
      const unPinned = this.initialColumnOrder.unpinned
        ? this.initialColumnOrder.unpinned.slice()
        : [];
      this.selectedColumns = [...pinned, ...unPinned];
      this.sortedPinnedColumns = pinned;
      this.sortedColumns = unPinned;
    } else {
      this.selectedColumns = this.allColumns.slice(); // deep copy
      this.sortedColumns = this.allColumns.slice(); // deep copy
    }
  }
  /* Clear the current state of the configuration in local storage */
  async clearConfiguration(
    key = this.localStorageKey,
    message = 'Configuration cleared successfully'
  ): Promise<void> {
    await this.$store.dispatch('localStorageModule/removeItem', key);
    this.dispatchSnackbar(message);
    this.loadConfiguration();
  }
  /* Save the current state of the configuration into local storage */
  async saveConfiguration(
    localStorageKey = this.localStorageKey,
    message = 'Configuration saved successfully',
    showSnackbarMessage = true
  ): Promise<void> {
    await this.$store.dispatch('localStorageModule/setItem', {
      key: localStorageKey,
      value: {
        selectedColumns: this.selectedColumns,
        sortedColumns: this.sortedColumns,
        sortColumn: this.sortColumn,
        isAscendingSortOrder: this.isAscendingSortOrder,
        sortedPinnedColumns: this.sortedPinnedColumns,
        numPaginatedRows: this.paginationRows
      }
    });
    /* We do not want the snackbar to show each time a selection is made on the drop down*/
    if (showSnackbarMessage) {
      this.dispatchSnackbar(message);
    }
  }
  /* Next pagination page */
  nextPage(): void {
    if (this.paginationIndex === this.pages - 1) return;
    this.paginationIndex = this.paginationIndex + 1;
  }
  /* Previus pagination page */
  previousPage(): void {
    if (this.paginationIndex === 0) return;
    this.paginationIndex = this.paginationIndex - 1;
  }
  /* To pagination page by index */
  toPage(index: number): void {
    this.paginationIndex = index;
  }
  /* Return just the paginated values based on pagination index */
  paginateRows(rows: TableRow[] | number[]): TableRow {
    const startIndex = this.paginationIndex * this.paginationRows;
    const endIndex = startIndex + this.paginationRows;
    return rows.slice(startIndex, endIndex);
  }

  toggleConfigurationSettings(): void {
    this.showColumnSettingsButtonClicked = true;
    this.showColumnSettings = !this.showColumnSettings;
    this.$emit('showConfiguration');
  }

  handleClickOutside(): void {
    if (!this.showColumnSettingsButtonClicked) this.showColumnSettings = false;
    this.showColumnSettingsButtonClicked = false;
  }
  /* Number of paginated pages */
  get pages(): number {
    if (!this.data) return 0;
    return Math.ceil(this.sortedData.length / this.paginationRows);
  }
  /* Calculate the currently selected row indexes, used for :checked value in the row selector */
  get selectedRowIndexes() {
    return this.selectedRows.map((row) => {
      return this.sortedData.findIndex(
        (item) => JSON.stringify(item) === JSON.stringify(row)
      );
    });
  }
  /* Get the current row indexes, this could change depending on row filtering */
  get rowIndexes() {
    const data = this.sortedData.map((row, index) => {
      return index;
    });
    return this.paginateRows(data);
  }
  /* Pinned ColumnConfigurations for ColumnSelection component  */
  get pinnedColumnConfigurations(): ColumnSelectionItem[] {
    return this.sortedPinnedColumns.map((column) => {
      return {
        name: column,
        isPinned: true,
        isSelected: this.selectedColumns.includes(column)
      } as ColumnSelectionItem;
    });
  }
  /* ColumnConfigurations for ColumnSelection component  */
  get columnConfigurations(): ColumnSelectionItem[] {
    return this.sortedColumns.map((column) => {
      return {
        name: column,
        isPinned: false,
        isSelected: this.selectedColumns.includes(column)
      } as ColumnSelectionItem;
    });
  }
  /* Current sortType calculated by sortColumn */
  get sortType(): TableSortType {
    const sortColumn = this.sortColumn;
    if (!this.data || !this.data[0] || !sortColumn) {
      return 'string';
    }
    const columnConfig = this.getConfig(sortColumn);
    if (columnConfig?.type) return columnConfig.type;
    const firstRowItem = this.data[0][sortColumn];
    if (typeof firstRowItem === 'number') {
      return 'number';
    }
    if (firstRowItem instanceof Date) {
      return 'date';
    }
    if (typeof firstRowItem === 'boolean') {
      return 'boolean';
    }
    return 'string';
  }
  /* Helper for all columns, regardless if filtered */
  get allColumns() {
    if (!this.data || !this.data.length) {
      return [];
    }
    return Object.keys(this.data[0]);
  }

  get sortedDataInView() {
    return this.paginateRows(this.sortedData);
  }
  /* Sorted raw data, this value reacts to sortColumn changes */
  get sortedData() {
    let data = this.data;
    if (!data) return [];
    /* Sort data */
    const sortColumn = this.sortColumn;
    if (this.isSortable && sortColumn) {
      const sortFn = TableSortFunctions[this.sortType](
        sortColumn,
        this.isAscendingSortOrder
      );
      data = data.sort(sortFn);
    }
    /* Filter data */
    if (this.allActiveFilters) {
      this.allActiveFilters.forEach((tableFilterItem: TableFilterItem) => {
        const filter = tableFilterItem.filter.bind(tableFilterItem);
        data = data?.filter(filter);
      });
    }
    return data;
  }

  /* sortedData but with hidden columns removed. used for downloading CSV and copying to clipboard */
  get tableData() {
    if (!this.sortedData) return [];
    return this.sortedData.map((obj) => {
      const newData: TableRow = {};
      for (const key in obj) {
        if (this.columns.includes(key) || this.pinnedColumns.includes(key)) {
          newData[key] = obj[key];
        }
      }
      return newData;
    });
  }
  /* Columns that are reactive to changes to sortedColumns, selectedColumns or sortedPinnedColumns */
  get columns() {
    if (
      !this.selectedColumns.length &&
      !this.sortedColumns.length &&
      !this.sortedPinnedColumns.length
    ) {
      this.loadConfiguration().catch((err: any) => err);
    }
    let columns = this.sortedColumns.filter((x) =>
      this.selectedColumns.includes(x)
    );
    if (this.sortedPinnedColumns) {
      columns = columns.filter((x) => !this.sortedPinnedColumns.includes(x));
    }
    if (this.sortedData) {
      const firstObject = this.sortedData[0];
      if (firstObject) {
        const keys = Object.keys(firstObject);
        columns = columns.filter((x) => keys.includes(x));
      }
    }
    return columns;
  }
  /* Row data that are reactive to changes to columns */
  get rows() {
    const rows = this.sortedData.map((item: any) => {
      return this.columns.reduce((acc: Array<any>, column) => {
        acc.push(item[column]);
        return acc;
      }, []);
    });
    return this.paginateRows(rows);
  }
  /* Pinned columns that are reactive to changes to selectedColumns or sortedPinnedColumns */
  get pinnedColumns() {
    return this.sortedPinnedColumns.filter((x) =>
      this.selectedColumns.includes(x)
    );
  }
  /* Pinned row data that are reactive to changes to pinnedColumns */
  get pinnedRows() {
    const rows = this.sortedData.map((item: any) => {
      return this.pinnedColumns.reduce((acc: Array<any>, column) => {
        acc.push(item[column]);
        return acc;
      }, []);
    });
    return this.paginateRows(rows);
  }
  /* Generate list of filters from configuration */
  get filters() {
    return this.allColumns.reduce((acc, column) => {
      const config = this.getConfig(column);
      if (config && config.filterItem) {
        acc.push(config.filterItem);
      }
      return acc;
    }, [] as TableFilterItem[]);
  }

  /*******************/
  /* Table Drop Down */
  /*******************/
  /* assigns the value from local storage or the default value */
  initSelectValue(val: TableStateConfiguration): void {
    this.paginationRows = val.numPaginatedRows
      ? val.numPaginatedRows
      : this.rowPaginationOptions[0].value;
  }

  handlePaginationOptionChange(val: number): void {
    this.paginationRows = val;
    if (this.localStorageKey) {
      this.saveConfiguration(this.localStorageKey, '', false);
    }
  }

  get filterDropDownDefault(): string | null {
    const findDefault = paginationItemsPerPage.find((item) => item.default);
    return findDefault ? findDefault.label : null;
  }

  /****************************************************/

  dispatchSnackbar(message: string): any {
    this.$store.dispatch('snackBarModule/enqueue', {
      message,
      timeout: this.SNACK_BAR_TIMEOUT
    });
  }

  handleHoverRow(rowIndex: number): void {
    this.hoverRowIndex = rowIndex;
  }
}
