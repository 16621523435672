/* eslint-disable @typescript-eslint/no-explicit-any */
import { NumberRange } from '@/models/NumberRange';
import { Component } from 'vue';
import QuickFilterFilter from './LegacyQuickFilterFilter';

export interface TableRow {
  [key: string]: any;
}

export type TableSortType = 'number' | 'string' | 'date' | 'boolean';

export interface TableComponentType<TableRowType = any, TableCellType = any> {
  componentRef: Component;
  componentProps?: (
    cellData: TableCellType,
    rowData: TableRowType
  ) => Record<string, any>;
}

export type TableConfiguration<TableRowType = any, TableCellType = any> = {
  columnTitle?: string;
  type?: TableSortType;
  component?: Component | TableComponentType<TableRowType, TableCellType>;
  filterItem?: TableFilterItem;
  isSortable?: boolean;
};

export type TableConfigurations<Type = any> = {
  [Key in keyof Type]?: TableConfiguration<Type, Type[Key]>;
};

export interface ColumnSelectionItem {
  isPinned?: boolean;
  isSelected?: boolean;
  name?: string;
}

export enum EntityAction {
  CREATE,
  UPDATE,
  DELETE,
  RESTORE,
  CLOSE
}

export type ColumnOrder<T = any> = {
  pinned?: (keyof T extends string ? keyof T : string)[];
  unpinned: (keyof T extends string ? keyof T : string)[];
};

export const TableSortFunctions = {
  number: (property: string, ascending: boolean) => {
    return (a: any, b: any) => {
      const columnA = Number(a[property]);
      const columnB = Number(b[property]);
      if (isNaN(columnA)) return 1;
      if (isNaN(columnB)) return -1;
      if (ascending) {
        return columnA < columnB ? -1 : 1;
      }
      return columnA > columnB ? -1 : 1;
    };
  },
  string: (property: string, ascending: boolean) => {
    return (a: any, b: any) => {
      if (!a[property] || !b[property]) return -1;
      const columnA = a[property].toString().toLowerCase();
      const columnB = b[property].toString().toLowerCase();
      if (ascending) {
        return columnA < columnB ? -1 : 1;
      }
      return columnA > columnB ? -1 : 1;
    };
  },
  date: (property: string, ascending: boolean) => {
    return (a: any, b: any) => {
      if (!a[property] || !b[property]) return -1;
      const columnA = new Date(a[property]).getTime();
      const columnB = new Date(b[property]).getTime();
      if (ascending) {
        return columnA < columnB ? -1 : 1;
      }
      return columnA > columnB ? -1 : 1;
    };
  },
  boolean: (property: string, ascending: boolean) => {
    return (a: any, b: any) => {
      const columnA = Boolean(a[property]);
      const columnB = Boolean(b[property]);

      if (ascending) {
        return columnA < columnB ? -1 : 1;
      }
      return columnA > columnB ? -1 : 1;
    };
  }
};

export enum TableFilterTypes {
  NUMBER,
  BOOLEAN,
  STRING,
  STRING_EXACT,
  LIST,
  NULL
}

export class TableFilterItem {
  public label: string;
  public column: string;
  public filterType: TableFilterTypes;
  public value?: any;
  public items: string[] = [];
  public numberRange: NumberRange = {} as NumberRange;

  constructor({
    label = '',
    column = '',
    filterType = TableFilterTypes.STRING,
    items = [] as string[]
  }) {
    this.label = label;
    this.column = column;
    this.filterType = filterType;
    this.items = items;
  }

  static fromQuickFilter(quickFilterItem: QuickFilterFilter): TableFilterItem {
    const tableFilter = new TableFilterItem({
      label: quickFilterItem.column,
      column: quickFilterItem.column,
      filterType: quickFilterItem.filterType
    });
    tableFilter.value = quickFilterItem.filter;
    return tableFilter;
  }

  filter(row: TableRow): boolean {
    switch (this.filterType) {
      case TableFilterTypes.BOOLEAN: {
        return this.filterBoolean(row);
      }
      case TableFilterTypes.NUMBER: {
        return this.filterNumberRange(row);
      }
      case TableFilterTypes.STRING_EXACT: {
        return this.filterStringExact(row);
      }
      case TableFilterTypes.NULL: {
        return this.filterNull(row);
      }
      default: {
        return this.filterString(row);
      }
    }
  }

  filterNumberRange(row: TableRow): boolean {
    return (
      row[this.column] >= this.numberRange.minValue &&
      row[this.column] <= this.numberRange.maxValue
    );
  }

  filterBoolean(row: TableRow): boolean {
    return row[this.column] === true || row[this.column] === 'true';
  }

  filterStringExact(row: TableRow): boolean {
    const item = `${row[this.column]}`.toString();
    return item.toLowerCase() === this.value.toLowerCase();
  }
  filterNull(row: TableRow): boolean {
    return row[this.column] === null;
  }

  filterString(row: TableRow): boolean {
    const item = `${row[this.column]}`.toString();
    return item.toLowerCase().includes(this.value?.toLowerCase());
  }
}
