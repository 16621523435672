
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconComponent from '@/components/IconComponent.vue';
import QuickFilterFilter from '@/models/Table/Legacy/LegacyQuickFilterFilter';

@Component<QuickFilterComponent>({ components: { IconComponent } })
export default class QuickFilterComponent extends Vue {
  @Prop({
    default: () => []
  })
  private readonly filters!: QuickFilterFilter[];

  @Prop({
    default: () => null
  })
  private readonly activeFilter!: QuickFilterFilter | null;

  @Prop({
    default: () => null
  })
  private readonly countStyle!: QuickFilterFilter | null;

  private filterIsActive(filter: string) {
    if (this.activeFilter) {
      return this.activeFilter.filter === filter;
    } else {
      return false;
    }
  }

  private handleFilterClick(filter: QuickFilterFilter) {
    this.$emit('quickFilter', filter);
  }
}
