var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "relative", attrs: { "data-cy": "tableComponent" } },
    [
      _vm.isQuickFilterable
        ? _c(
            "quick-filter-component",
            {
              staticClass: "mb-4",
              attrs: {
                "data-cy": "quickFilterComponent",
                filters: _vm.quickFilterItems,
                activeFilter: _vm.activeQuickFilter,
              },
              on: { quickFilter: _vm.quickFilterHandler },
            },
            [_vm._t("quick-filter")],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex my-2" },
        [
          _vm.isColumnEditable
            ? _c(
                "div",
                { staticClass: "mr-2" },
                [
                  _c(
                    "button-component",
                    {
                      attrs: { "data-cy": "tableConfigurationButton" },
                      on: { click: _vm.toggleConfigurationSettings },
                    },
                    [
                      _c("icon-component", {
                        staticClass: "block",
                        attrs: { name: "gear", width: 14, height: 14 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isFilterable
            ? _c("table-filter-component", {
                attrs: {
                  showMenu: _vm.showMenu,
                  activeFilter: _vm.activeFilter,
                  filters: _vm.filters,
                },
                on: {
                  activeFilter: _vm.tableFilterActiveFilter,
                  selectFilter: _vm.tableFilterSelectFilter,
                  toggleMenu: _vm.toggleMenu,
                  closeAllMenus: _vm.closeAllMenus,
                  clearFilter: _vm.clearActiveFilter,
                  rangeChange: _vm.filterRangeChange,
                },
              })
            : _vm._e(),
          _vm.localStorageKey && _vm.canEditPaginatedRows
            ? _c("table-drop-down-component", {
                staticClass: "ml-2",
                attrs: {
                  itemValue: _vm.paginationRows,
                  itemValues: _vm.rowPaginationOptions,
                  placeholder: _vm.filterDropDownDefault,
                },
                on: { input: _vm.handlePaginationOptionChange },
              })
            : _vm._e(),
          _c(
            "div",
            [_vm._t("settings-left", null, { dataCy: "settingLeftSlot" })],
            2
          ),
          _c("div", { staticClass: "ml-auto" }, [_vm._t("settings-right")], 2),
          _vm.canDownloadCSV
            ? _c(
                "div",
                { staticClass: "flex gap-2" },
                [
                  _c("button-component", { on: { click: _vm.downloadData } }, [
                    _c(
                      "div",
                      { staticClass: "flex items-center whitespace-nowrap" },
                      [
                        _c("icon-component", {
                          staticClass: "pr-2 fill-current",
                          attrs: { name: "download", width: 24, height: 24 },
                        }),
                        _vm._v(" Download CSV "),
                      ],
                      1
                    ),
                  ]),
                  _c("button-component", { on: { click: _vm.copyData } }, [
                    _c(
                      "div",
                      { staticClass: "flex items-center whitespace-nowrap" },
                      [
                        _vm.copyButtonText === "Table Copied"
                          ? _c("icon-component", {
                              staticClass: "pr-2",
                              attrs: {
                                name: "checkGreen",
                                fill: "green",
                                width: 24,
                                height: 24,
                              },
                            })
                          : _c("icon-component", {
                              staticClass: "pr-2 fill-current",
                              attrs: { name: "copy", width: 24, height: 24 },
                            }),
                        _vm._v(" " + _vm._s(_vm.copyButtonText) + " "),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.allActiveFilters.length
        ? _c("div", { staticClass: "mb-4 text-xs" }, [
            _c(
              "div",
              { staticClass: "flex flex-wrap gap-1" },
              _vm._l(_vm.allActiveFilters, function (filter, index) {
                return _c(
                  "button",
                  {
                    key: index,
                    staticClass:
                      "p-1 px-3 text-xs text-blue-500 border border-blue-500 rounded-full cursor-pointer whitespace-nowrap",
                    attrs: { "data-cy": "table-active-filter-button" },
                    on: {
                      click: function ($event) {
                        return _vm.clearActiveFilter(filter)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(filter.label))]),
                    _vm._v(" - "),
                    !isNaN(filter.numberRange.minValue) &&
                    !isNaN(filter.numberRange.maxValue)
                      ? _c("span", { staticClass: "italic" }, [
                          _vm._v(
                            _vm._s(filter.numberRange.minValue) +
                              "-" +
                              _vm._s(filter.numberRange.maxValue)
                          ),
                        ])
                      : _c("span", { staticClass: "italic" }, [
                          _vm._v(_vm._s(filter.value)),
                        ]),
                    _vm._v(" X "),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm.sortedData.length
        ? _c(
            "div",
            { staticClass: "flex overflow-x-auto" },
            [
              _vm.isSelectable
                ? _c("row-selection-component", {
                    attrs: {
                      "data-cy": "tableRowSelectionComponent",
                      rowIndexes: _vm.rowIndexes,
                      selectedRowIndexes: _vm.selectedRowIndexes,
                      compact: _vm.compact,
                    },
                    on: {
                      rowChecked: _vm.onRowChecked,
                      allRowsChecked: _vm.onAllRowsChecked,
                    },
                  })
                : _vm._e(),
              _vm.pinnedColumns && _vm.pinnedColumns.length
                ? _c("legacy-base-table-component", {
                    staticClass: "flex-shrink-0 border-r pinned-table",
                    attrs: {
                      sortedData: _vm.sortedDataInView,
                      rows: _vm.pinnedRows,
                      columns: _vm.pinnedColumns,
                      isSortable: _vm.isSortable,
                      isRowClickable: _vm.isRowClickable,
                      tableConfigurations: _vm.tableConfigurations,
                      isAscendingSortOrder: _vm.isAscendingSortOrder,
                      sortColumn: _vm.sortColumn,
                      formatter: _vm.formatColumnTitle,
                      compact: _vm.compact,
                      activeHoverRow: _vm.hoverRowIndex,
                    },
                    on: {
                      cellEvent: _vm.emitCellEvent,
                      rowClicked: _vm.emitRowClickedEvent,
                      sortData: _vm.sortData,
                      hoverRow: _vm.handleHoverRow,
                    },
                  })
                : _vm._e(),
              _c("legacy-base-table-component", {
                staticClass: "w-full",
                attrs: {
                  sortedData: _vm.sortedDataInView,
                  rows: _vm.rows,
                  columns: _vm.columns,
                  isSortable: _vm.isSortable,
                  isRowClickable: _vm.isRowClickable,
                  tableConfigurations: _vm.tableConfigurations,
                  isAscendingSortOrder: _vm.isAscendingSortOrder,
                  sortColumn: _vm.sortColumn,
                  formatter: _vm.formatColumnTitle,
                  isScrollable: true,
                  compact: _vm.compact,
                  activeHoverRow: _vm.hoverRowIndex,
                  columnDataTextWrapping: _vm.columnDataTextWrapping,
                },
                on: {
                  cellEvent: _vm.emitCellEvent,
                  rowClicked: _vm.emitRowClickedEvent,
                  sortData: _vm.sortData,
                  hoverRow: _vm.handleHoverRow,
                },
              }),
            ],
            1
          )
        : _c("empty-table-message-component", {
            attrs: { message: _vm.emptyTableMessage },
          }),
      _vm.pages > 1
        ? _c("div", { staticClass: "flex items-start mt-4 text-sm" }, [
            _c(
              "button",
              {
                staticClass: "pr-2 font-bold text-blue-400 focus:outline-none",
                on: { click: _vm.previousPage },
              },
              [_vm._v(" < ")]
            ),
            _c(
              "div",
              { staticClass: "flex overflow-x-auto" },
              _vm._l(_vm.pages, function (page, index) {
                return _c(
                  "button",
                  {
                    key: index,
                    staticClass:
                      "px-1 mx-1 text-blue-400 border-b-2 border-transparent focus:outline-none",
                    class: {
                      "border-blue-400": _vm.paginationIndex === index,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toPage(index)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(page) + " ")]
                )
              }),
              0
            ),
            _c(
              "button",
              {
                staticClass: "pl-2 font-bold text-blue-400 focus:outline-none",
                on: { click: _vm.nextPage },
              },
              [_vm._v(" > ")]
            ),
          ])
        : _vm._e(),
      _vm.showSimpleTable
        ? _c("modal-component", [
            _c(
              "div",
              { ref: "simpleTable", staticClass: "p-4 overflow-auto h-96" },
              [_c("simple-table", { attrs: { tableData: _vm.tableData } })],
              1
            ),
          ])
        : _vm._e(),
      _vm.isColumnEditable
        ? _c(
            "slide-out-component",
            {
              directives: [
                {
                  name: "clickOutside",
                  rawName: "v-clickOutside",
                  value: _vm.handleClickOutside,
                  expression: "handleClickOutside",
                },
              ],
              attrs: { open: _vm.showColumnSettings },
            },
            [
              _c("div", { staticClass: "p-6" }, [
                _c(
                  "div",
                  { staticClass: "relative flex items-center justify-between" },
                  [
                    _c("h3", {}, [_vm._v("Table settings")]),
                    _c(
                      "button",
                      {
                        staticClass: "focus:outline-none",
                        attrs: { "data-cy": "closeConfigurationButton" },
                        on: {
                          click: function ($event) {
                            _vm.showColumnSettings = !_vm.showColumnSettings
                          },
                        },
                      },
                      [_vm._v(" Close ")]
                    ),
                  ]
                ),
                _c("div", { staticClass: "mt-2 text-blue-900" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.sortedPinnedColumns.length) +
                      "/" +
                      _vm._s(_vm.maxPins) +
                      " PINNED "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "block w-full border-b" }),
              _c(
                "div",
                { staticClass: "px-6 overflow-y-auto h-3/4" },
                [
                  _c("column-selection-component", {
                    staticClass: "bg-gray-100",
                    attrs: {
                      columns: _vm.pinnedColumnConfigurations,
                      formatter: _vm.formatColumnTitle,
                    },
                    on: {
                      togglePinnedColumn: _vm.togglePinnedColumn,
                      swapColumns: _vm.swapPinnedColumns,
                      selectColumn: _vm.onSelectColumn,
                    },
                  }),
                  _c("column-selection-component", {
                    attrs: {
                      columns: _vm.columnConfigurations,
                      formatter: _vm.formatColumnTitle,
                    },
                    on: {
                      togglePinnedColumn: _vm.togglePinnedColumn,
                      swapColumns: _vm.swapColumns,
                      selectColumn: _vm.onSelectColumn,
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "block w-full border-b" }),
              _c(
                "div",
                { staticClass: "flex mt-4" },
                [
                  _vm.localStorageKey
                    ? _c(
                        "button-component",
                        {
                          staticClass: "ml-auto mr-2",
                          attrs: { "data-cy": "clearConfigurationButton" },
                          on: {
                            click: function ($event) {
                              return _vm.clearConfiguration(
                                _vm.localStorageKey,
                                "Configuration cleared successfully"
                              )
                            },
                          },
                        },
                        [_vm._v(" Clear Configuration ")]
                      )
                    : _vm._e(),
                  _vm.localStorageKey
                    ? _c(
                        "button-component",
                        {
                          staticClass: "ml-2 mr-10",
                          attrs: {
                            "data-cy": "saveConfigurationButton",
                            textOrBorderColor: "white",
                            bg: "val-button-blue",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.saveConfiguration(_vm.localStorageKey)
                            },
                          },
                        },
                        [_vm._v(" Save Configuration ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }