var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "clickOutside",
          rawName: "v-clickOutside",
          value: _vm.closeAllMenus,
          expression: "closeAllMenus",
        },
      ],
      staticClass: "relative flex",
    },
    [
      _c(
        "button-component",
        { staticClass: "flex items-center", on: { click: _vm.toggleMenu } },
        [
          _c("icon-component", {
            attrs: { name: "filter", width: 14, height: 14 },
          }),
          _c("div", { staticClass: "mx-2 whitespace-nowrap" }, [
            _vm._v("Filter by..."),
          ]),
          _c("icon-component", {
            attrs: { name: "chevronDownThin", width: 14, height: 8 },
          }),
        ],
        1
      ),
      _vm.showMenu
        ? _c(
            "div",
            { staticClass: "absolute z-20 bg-white border rounded menu" },
            _vm._l(_vm.filters, function (filter, index) {
              return _c("div", { key: index, staticClass: "relative" }, [
                _vm.isBoolean(filter)
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "flex w-full px-6 py-4 cursor-pointer whitespace-nowrap hover:bg-blue-100 focus:outline-none",
                        on: {
                          click: function ($event) {
                            return _vm.selectFilter(filter)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(filter.label) + " ")]
                    )
                  : _c(
                      "button",
                      {
                        staticClass:
                          "relative flex w-full px-6 py-4 cursor-pointer whitespace-nowrap hover:bg-blue-100 focus:outline-none",
                        on: {
                          click: function ($event) {
                            return _vm.setActiveFilter(filter)
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(filter.label) + " "),
                        _c(
                          "div",
                          { staticClass: "pl-8 ml-auto text-sm text-gray-500" },
                          [_vm._v(">")]
                        ),
                      ]
                    ),
                filter === _vm.activeFilter
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "absolute top-0 z-10 bg-white border rounded submenu left-full",
                      },
                      [
                        _vm.isNumber(filter)
                          ? _c(
                              "div",
                              { staticClass: "p-6" },
                              [
                                _c("number-range-component", {
                                  staticClass: "mt-6",
                                  attrs: { numberRange: filter.numberRange },
                                  on: {
                                    rangeChange: function ($event) {
                                      return _vm.onRangeChange(filter, $event)
                                    },
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "flex flex-row-reverse" },
                                  [
                                    _c(
                                      "button-component",
                                      {
                                        staticClass: "mt-2",
                                        on: { click: _vm.closeAllMenus },
                                      },
                                      [_vm._v(" Done ")]
                                    ),
                                    _c(
                                      "button-component",
                                      {
                                        staticClass: "mt-2",
                                        attrs: {
                                          border: false,
                                          textOrBorderColor: "red-500",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearFilter(filter)
                                          },
                                        },
                                      },
                                      [_vm._v(" Clear ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm.isString(filter)
                          ? _c("div", { staticClass: "w-48 p-6" }, [
                              _c("input", {
                                staticClass:
                                  "w-full p-2 border rounded outline-none",
                                attrs: {
                                  type: "text",
                                  placeholder: "Enter value",
                                  maxlength: "20",
                                },
                                domProps: { value: undefined },
                                on: {
                                  input: function ($event) {
                                    return _vm.selectFilter(
                                      filter,
                                      $event.target.value
                                    )
                                  },
                                  enter: _vm.closeSubMenus,
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "flex flex-row-reverse" },
                                [
                                  _c(
                                    "button-component",
                                    {
                                      staticClass: "mt-2",
                                      on: { click: _vm.closeAllMenus },
                                    },
                                    [_vm._v(" Done ")]
                                  ),
                                  _c(
                                    "button-component",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        border: false,
                                        textOrBorderColor: "red-500",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter(filter)
                                        },
                                      },
                                    },
                                    [_vm._v(" Clear ")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm.isList(filter)
                          ? _c(
                              "div",
                              { staticClass: "w-48 p-6" },
                              [
                                _c("dropdown", {
                                  attrs: {
                                    values: filter.items,
                                    placeholder: "Select a value",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.selectFilter(filter, $event)
                                    },
                                  },
                                  model: {
                                    value: filter.value,
                                    callback: function ($$v) {
                                      _vm.$set(filter, "value", $$v)
                                    },
                                    expression: "filter.value",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "flex flex-row-reverse" },
                                  [
                                    _c(
                                      "button-component",
                                      {
                                        staticClass: "mt-2",
                                        on: { click: _vm.closeAllMenus },
                                      },
                                      [_vm._v(" Done ")]
                                    ),
                                    _c(
                                      "button-component",
                                      {
                                        staticClass: "mt-2",
                                        attrs: {
                                          border: false,
                                          textOrBorderColor: "red-500",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearFilter(filter)
                                          },
                                        },
                                      },
                                      [_vm._v(" Clear ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }