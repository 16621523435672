import { render, staticRenderFns } from "./LegacyTableComponent.vue?vue&type=template&id=a64b51de&"
import script from "./LegacyTableComponent.vue?vue&type=script&lang=ts&"
export * from "./LegacyTableComponent.vue?vue&type=script&lang=ts&"
import style0 from "./LegacyTableComponent.vue?vue&type=style&index=0&id=a64b51de&nonce=35bafb1ce99aef3ab068afbaabae8f21fd9b9f02d3a9442e364fa92c0b3eeef0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspace/src/services/webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a64b51de')) {
      api.createRecord('a64b51de', component.options)
    } else {
      api.reload('a64b51de', component.options)
    }
    module.hot.accept("./LegacyTableComponent.vue?vue&type=template&id=a64b51de&", function () {
      api.rerender('a64b51de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/models/Table/Legacy/LegacyTableComponent.vue"
export default component.exports